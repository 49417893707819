import swal from "sweetalert";
import {
    getElibraryContentList,
    uploadDeveloperFile,
    uploadDesignerConceptmap,
    uploadDesignerPDF,
    rejectReasons,
    developerApprovedRejectContent,
    finalUploadDesignerConceptmap
} from '../../services/ElibraryServices';

import {
    ELIBRARY_CONTENT_REJECT_REASONS,
    GET_ELIBRARY_CONTENT_LIST,
    UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY,
    ELIBRARY_LOADING,
    APPROVED_REJECT_ELIBRARY_CONTENT_STATUS
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from "../../store/actions/GlobalAction";

export function getElibraryContentListData(page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status, history) {
    return (dispatch) => {
        getElibraryContentList(page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status)
            .then((response) => {
                // console.log('response....', response.data)
                if (response.data.status == 200) {
                    dispatch(getContentListAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function developerRejectApprovedContentData(id, approve_status, reason, page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status, history) {
    return (dispatch) => {
        developerApprovedRejectContent(id, approve_status, reason)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getElibraryContentListData(page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status, history));
                } else if (response.data.status == 400) {
                    dispatch(getElibraryContentListData(page, exam_category_id, board_id, exam_type_id, subject_id, class_no, chapter_id, status, history));
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function uploadContentDeveloperFile(id, content, concept_map, hyperlink, hyperlink_data, status, citation, onGoBackHandler, history) {
    return (dispatch) => {
        uploadDeveloperFile(id, content, concept_map, hyperlink, hyperlink_data, status, citation)
            .then((response) => {
                dispatch(elibraryloadingAction(false));
                utility.showSuccess(response.data.msg);
                onGoBackHandler();
            })
            .catch((error) => {
                dispatch(elibraryloadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function uploadContentDesignerPDF(id, file, generateHyperlink, history) {
    return (dispatch) => {
        uploadDesignerPDF(id, file)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.file_path != '') {
                        generateHyperlink(response.data);
                        localStorage.setItem('filePath', response.data.file_path)
                        dispatch(uploadDesignerPDFAction(response.data));
                        dispatch(globalLoadingAction(false));
                    } else {
                        utility.showError("File not uploaded");
                    }
                } else {
                    utility.showError("File not uploaded");
                }
            })
            .catch((error) => {
                utility.showError("File not uploaded");
                dispatch(globalLoadingAction(false));
                // utility.showError(error.response.data);
            });
    };
}

export function finalUploadContentDesignerConceptMap(id, history) {
    return (dispatch) => {
        finalUploadDesignerConceptmap(id)
            .then((response) => {
                if (response.status === 200) {
                    utility.showSuccess(response.data.msg);
                    history.replace({ pathname: "/content-list" });
                }
            })
            .catch((error) => {
                dispatch(elibraryloadingAction(false));
            });
    };
}

export function uploadContentDesignerConceptMap(state, file, status, callBack, history) {
    return (dispatch) => {
        uploadDesignerConceptmap(state.id, file, status)
            .then((response) => {
                if (response.status === 200) {
                    utility.showSuccess(response.data.msg);
                    callBack(response.data);
                } else {
                    utility.showError(response.data.msg);
                    dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                dispatch(elibraryloadingAction(false));
            });
    };
}

export function rejectReasonsData(history) {
    return (dispatch) => {
        rejectReasons()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(rejectReasonsAction(response.data));
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function getContentListAction(data) {
    return {
        type: GET_ELIBRARY_CONTENT_LIST,
        payload: data,
    };
}

export function approvedRejectContentAction(data) {
    return {
        type: APPROVED_REJECT_ELIBRARY_CONTENT_STATUS,
        payload: data,
    };
}

export function uploadDesignerPDFAction(data) {

    return {
        type: UPLOAD_CONTENT_DESIGNER_PDF_ELIBRARY,
        payload: data,
    };
}

export function rejectReasonsAction(data) {
    return {
        type: ELIBRARY_CONTENT_REJECT_REASONS,
        payload: data,
    };
}

export function elibraryloadingAction(status) {
    return {
        type: ELIBRARY_LOADING,
        payload: status,
    };
}